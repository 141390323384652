import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollTop = () => {
    const { pathname } = useLocation();
    useLayoutEffect(() => {
        window.scrollBy({ top: -1 * window.scrollY, behavior: "smooth" });
    }, [pathname]);

    return null;
};

export default ScrollTop;
