import React, { useState } from "react";
import API_URL from "../Api";

export default function ImageOptimizer({
  formats,
  width,
  height,
  style,
  styleImage,
  className,
  title,
  alt,
  lazy = true,
}) {
  const [error, setError] = useState(false);

  if (!formats) return (
    <div style={style} className={className}>

    </div>
  );

  // Fonction pour s'assurer que formats est un objet
  const parseFormats = () => {
    try {
      return typeof formats === "string" ? JSON.parse(formats) : formats;
    } catch (e) {
      console.error("Error parsing formats:", e);
      return {};
    }
  };

  const handleImageError = () => {
    setError(true);
  };

  // Parse formats une fois de manière sécurisée
  const safeFormats = parseFormats();

  return (
      <picture style={style} className={className}>
          {safeFormats?.small && (
              <React.Fragment>
                  <source
                      media="(max-width: 767px)"
                      srcSet={API_URL + safeFormats?.small.path}
                  />
              </React.Fragment>
          )}
          {safeFormats.medium && (
              <source
                  media="(max-width: 1023px)"
                  srcSet={API_URL + safeFormats.medium.path}
              />
          )}
          {safeFormats.large && (
              <React.Fragment>
                  <source
                      media="(min-width: 1024px)"
                      srcSet={API_URL + safeFormats.large.path}
                  />
              </React.Fragment>
          )}
          {error ? (
              // Affiche un fallback en cas d'erreur dans l'URL de l'image
              <img
                  src={API_URL + "/uploads/default_image.webp"}
                  alt="Absence de visuel"
                  width={width}
                  height={height}
                  loading={lazy ? "lazy" : "eager"}
                  title="Absence de visuel"
              />
          ) : (
              <img
                  src={
                      safeFormats.medium
                          ? API_URL + safeFormats.medium.path
                          : API_URL + safeFormats?.small.path
                  }
                  alt={alt ? alt : safeFormats?.small.filename}
                  width={width}
                  height={height}
                  title={title ? title : safeFormats?.small.filename}
                  loading={lazy ? "lazy" : "eager"}
                  className={className}
                  onError={handleImageError}
                  style={styleImage}
              />
          )}
      </picture>
  );
}
