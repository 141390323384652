import axios from "axios";
import API_URL from "../Api";

const axiosInstance = axios.create({
    baseURL: API_URL,
    timeout: 20000,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    },
});

// intercepteur de réponse
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            window.location.href = "/espace-pro/connexion"; // Redirige vers la page de connexion
        }
        return Promise.reject(error); // Propager l'erreur pour une gestion ultérieure si nécessaire
    }
);

export default axiosInstance;
