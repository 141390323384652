import { lazy } from "react";

import AidesVenteProduct from "../views/Sellers/AidesVenteProduct";
import { ProtectedRoutes } from "./ProtectedRoutes";

const MainLayout = lazy(() => import("../layouts/MainLayout"));

const CataloguesTarifs = lazy(() => import("../views/Sellers/CataloguesTarifs"));
const EspacePro = lazy(() => import("../views/Sellers/EspacePro"));
const Commandes = lazy(() => import("../views/Sellers/Commandes"));
const DetailsCommandes = lazy(() => import("../views/Sellers/Detailscommandes"));
const InfoCompte = lazy(() => import("../views/Sellers/InfoCompte"));
const InfoProfil = lazy(() => import("../views/Sellers/InfoProfil"));
const InfoPassword = lazy(() => import("../views/Sellers/InfoPassword"));
const CarnetAdresses = lazy(() => import("../views/Sellers/CarnetAdresses"));
const AidesVente = lazy(() => import("../views/Sellers/AidesVente"));
const Connexion = lazy(() => import("../views/Sellers/Connexion"));
const Inscription = lazy(() => import("../views/Sellers/Inscription"));
const ForgotPassword = lazy(() => import("../views/Sellers/ForgotPassword"));
const ResetPassword = lazy(() => import("../views/Sellers/ResetPassword"));
const FinalisationCompte = lazy(() => import("../views/Sellers/FinalisationCompte"));
const FinalisationCompteSansPw = lazy(() => import("../views/Sellers/FinalisationCompteSansPw"));

const NotFound = lazy(() => import("../views/NotFound"));

const SellerRoutes = {
    element: <MainLayout />,
    path: "espace-pro",
    children: [
        {
            path: "connexion",
            element: <Connexion />,
        },
        {
            path: "mot-de-passe-oublie",
            element: <ForgotPassword />,
        },
        {
            path: "reinitialiser-mot-de-passe/:token",
            element: <ResetPassword />,
        },
        {
            path: "inscription",
            element: <Inscription />,
        },
        {
            path: "finalisation-inscription/:token",
            element: <FinalisationCompte />,
        },
        {
            path: "finalisation-inscription-sans-mot-de-passe/:token",
            element: <FinalisationCompteSansPw />,
        },
        {
            path: "*",
            element: <NotFound />,
        },

        // ESPACE PROTÉGÉ
        {
            element: <ProtectedRoutes />,
            children: [
                {
                    path: "",
                    element: <EspacePro />,
                },
                {
                    path: "mes-commandes",
                    element: <Commandes />,
                },
                {
                    path: "mes-commandes/:slug",
                    element: <DetailsCommandes />,
                },
                {
                    path: "mes-informations",
                    element: <InfoCompte />,
                },
                {
                    path: "mes-informations/compte",
                    element: <InfoProfil />,
                },
                {
                    path: "mes-informations/mot-de-passe",
                    element: <InfoPassword />,
                },
                {
                    path: "mes-informations/carnet-adresse",
                    element: <CarnetAdresses />,
                },
                {
                    path: "aides-vente",
                    element: <AidesVente />,
                },
                {
                    path: "aides-vente/:slug",
                    element: <AidesVenteProduct />,
                },
                {
                    path: "catalogues-tarifs",
                    element: <CataloguesTarifs />,
                },
            ],
        },
    ],
};

export default SellerRoutes;
