import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import ImageOptimizer from "../../utils/ImageOptimizer";

const ColorSelector = (props) => {
    const { colors, defaultColors = 0, onChange, displayMax = 8, allDisplayed = false, selection = true } = props;

    const [color, setColor] = useState(selection ? colors[defaultColors] : null);

    const [displayedColors, setDisplayedColors] = useState(colors);

    const [modalColorOpen, setModalColorOpen] = useState(false);

    const openModalColor = () => {
        setModalColorOpen(true);
    };

    const closeModalColor = () => {
        setModalColorOpen(false);
    };

    const handleColorSelection = (selectedColor) => {
        const index = displayedColors.findIndex((item) => item.ref === selectedColor.ref);
        if (index > displayMax - 1 && !allDisplayed) {
            // Ajouter l'élément en première position et le retire de son ancienne postion */
            setDisplayedColors((prevState) => {
                return [selectedColor, ...prevState.slice(0, index).concat(prevState.slice(index + 1))];
            });

            // Fermez la modal
            closeModalColor();
        }
        // Définissez l'élément sélectionné comme ring
        if (selection) {
            setColor(selectedColor);
            onChange(selectedColor);
        }
    };

    return (
        <div className="flex items-flex justify-start flex-wrap">
            {displayedColors.slice(0, allDisplayed ? displayedColors.length : displayMax).map((item, index) => (
                <div key={index} className="flex flex-col items-flex justify-center">
                    <div
                        className={`${selection ? "cursor-pointer" : ""} ${
                            color === item ? "border-green border-4" : "border-beige"
                        } overflow-hidden transition-[border-width] ease bg-cover w-[50px] h-[50px] m-[5px] rounded-[50px] border-solid border-2`}
                        style={{
                            ...(item.type === "color"
                                ? { backgroundColor: item.color }
                                : item.type === "bgImage"
                                ? { backgroundImage: `url(${item.bgImage})` }
                                : {}),
                        }}
                        onClick={() => handleColorSelection(item)}
                    >
                        <ImageOptimizer
                            className="w-full object-cover flex justify-center items-center"
                            formats={item.File.formats}
                            width={256}
                            height={256}
                            alt={"Pied de lit Margot " + item.name}
                            title={item.name}
                            style={{ width: "100%", height: "100%" }}
                            styleImage={{ width: "100%", height: "100%", ObjectFit: "full" }}
                        />
                    </div>
                    <p className="w-full text-center" style={{ fontFamily: "futura_stdmedium_condensed" }}>
                        {item.ref}
                    </p>
                </div>
            ))}
            {colors.length > displayMax && !allDisplayed && (
                <button onClick={openModalColor} className="border-solid border-2 border-beige rounded-[50px] w-[50px] h-[50px] m-[5px]">
                    <AddIcon className="text-beige" />
                </button>
            )}
            {!allDisplayed && modalColorOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
                    <div className="relative z-50 mx-auto my-12 bg-white rounded-lg shadow-lg w-fit max-w-[50vw]">
                        <div className="flex justify-between p-4 border-b">
                            <h3 className="text-lg font-semibold text-center">Teintes</h3>
                            <button onClick={closeModalColor} className="text-gray-600">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div className="p-4">
                            <div className="flex justify-center items-center flex-wrap">
                                {displayedColors.slice(displayMax).map((item, index) => (
                                    <div key={index} className="flex flex-col items-flex justify-center">
                                        <div
                                            className={`${selection ? "cursor-pointer" : ""} ${
                                                color === item ? "border-green border-4" : "border-beige"
                                            } overflow-hidden transition-[border-width] ease bg-cover w-[50px] h-[50px] m-[5px] rounded-[50px] border-solid border-2`}
                                            style={{
                                                ...(item.type === "color"
                                                    ? { backgroundColor: item.color }
                                                    : item.type === "bgImage"
                                                    ? { backgroundImage: `url(${item.bgImage})` }
                                                    : {}),
                                            }}
                                            onClick={() => handleColorSelection(item)}
                                        >
                                            <ImageOptimizer
                                                className="w-full object-cover flex justify-center items-center"
                                                formats={item.File.formats}
                                                width={256}
                                                height={256}
                                                alt={"Pied de lit Margot " + item.name}
                                                title={item.name}
                                                style={{ width: "100%", height: "100%" }}
                                                styleImage={{ width: "100%", height: "100%", ObjectFit: "full" }}
                                            />
                                        </div>
                                        <p className="w-full text-center" style={{ fontFamily: "futura_stdmedium_condensed" }}>
                                            {item.ref}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ColorSelector;
