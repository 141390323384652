import React, { useContext, useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { ModalCartContext } from "./components/utils/Providers";
import { ConsentContext } from "./contexts/ConsentContext";
import { NotificationProvider } from "./contexts/NotifContext";
import Routes from "./routes";
import MetaTagsMaster from "./utils/MetaTagsMaster";
import ScrollTop from "./utils/ScrollTop";

function App() {
    const { consent } = useContext(ConsentContext);
    const location = useLocation();
    const [modalCart, setModalCart] = useState(false);

    function gtag() {
        window.dataLayer.push(arguments);
    }

    useEffect(() => {
        // Suivi de la pageview si le consentement pour les analytics est accordé
        gtag("event", "pageview", {
            page_path: window.location.pathname + window.location.search,
        });
    }, [location]);

    useEffect(() => {
        try {
            if (consent && process.env.NODE_ENV === "production") {
                gtag("consent", "update", {
                    analytics_storage: consent.analytics_storage ? "granted" : "denied",
                    ad_storage: consent.ad_storage ? "granted" : "denied",
                    ad_user_data: consent.ad_user_data ? "granted" : "denied",
                    ad_personalization: consent.ad_personalization ? "granted" : "denied",
                    personalization_storage: consent.personalization_storage ? "granted" : "denied",
                    functionality_storage: "granted", // Supposant que cela reste toujours activé
                    security_storage: "granted", // Supposant que cela reste toujours activé
                });
            }
        } catch (error) {
            console.error("Error reading or updating cookie consent:", error);
            // localStorage.setItem("cookieConsent", null);
        }
    }, [consent]);

    return (
        <HelmetProvider>
            <NotificationProvider>
                <MetaTagsMaster />
                <ModalCartContext.Provider value={{ modalCart, setModalCart }}>
                    <ScrollTop />
                    <Routes />
                </ModalCartContext.Provider>
            </NotificationProvider>
        </HelmetProvider>
    );
}

export default App;
