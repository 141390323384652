import Breadcrumbs from "@mui/material/Breadcrumbs";
import React from "react";
import { Link } from "react-router-dom";

// accepte un tableau contenant alias et url des pages à afficher dans le fil
export default function Ariane({ arianeItem, className, bgColor }) {
  return (
    <>
      {arianeItem && (
        <div className={`${className} ariane`}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={"/"}
            className="FuturaBook text-base"
            style={{ color: bgColor === "#21232E" ? "white" : "black" }}
          >
            {arianeItem.map((term) => (
              <Link
                underline="hover"
                key={"term" + term[0] + term[1]}
                to={term[0]}
                className="FuturaBook text-base"
                style={{ color: bgColor === "#21232E" ? "white" : "black" }}
                title="Fil d'arianne"
              >
                {term[1]}
              </Link>
            ))}
          </Breadcrumbs>
        </div>
      )}
    </>
  );
}
