import { Button } from "@mui/material";
import React from "react";
import { askAideVente } from "../../api/Contact";
import { useNotification } from "../../contexts/NotifContext";
import useUserStore from "../../contexts/UserStore";
import { theme } from "../../theme";
import ImageOptimizer from "../../utils/ImageOptimizer";

export default function ResumeAidesVente(props) {
    const { product, choice } = props;
    const showToast = useNotification();
    const { getUser } = useUserStore((state) => ({
        getUser: state.getUser,
    }));
    const user = getUser();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = await askAideVente({
                user: {
                    email: user.email,
                    name: user.company_name,
                },
                product: {
                    name: product.name,
                },
                choice: choice
                    ? choice.map((item) => ({
                          name: item.name,
                          id: item.id,
                          selectedColor: {
                              id: item.selectedColor.id,
                              name: item.selectedColor.name,
                              ref: item.selectedColor.ref,
                          },
                          selectedRingColor: item.selectedRingColor
                              ? {
                                    id: item.selectedRingColor.id,
                                    name: item.selectedRingColor.name,
                                    ref: item.selectedRingColor.ref,
                                }
                              : null,
                      }))
                    : null,
            });
            if (data) {
                showToast("La demande a été envoyée avec succès !", {
                    state: "success",
                });
            }
        } catch (error) {
            console.log(error);
            showToast("Une erreur est survenue lors de l'envoie du mail. Merci de réessayer plus tard.");
        }
    };

    return (
        <div className="flex flex-col gap-4">
            <p className="FuturaMediumCd text-4xl pb-[32px]">Aide à la vente demandé : </p>
            <div className="flex flex-row gap-4">
                <div className="flex flex-col gap-4">
                    <p className="FuturaMediumCd text-4xl self-center bg-beige bg-opacity-50 rounded-[4px] py-[4px] px-[14px]">{product.name}</p>
                    <div className="w-[20rem] h-[20rem] border-r-4 overflow-hidden">
                        <img src={product.link} alt="Produit" className="object-contain w-full" />
                    </div>
                </div>
                <div className="flex flex-row flex-wrap gap-4">
                    {choice ? (
                        choice.map(
                            (item, index) =>
                                item && (
                                    <div key={index} className="flex flex-row gap-4 gap-y-8 pr-6 w-[25rem]">
                                        <div className="w-[10rem] h-[10rem] overflow-hidden bg-beige bg-opacity-50 rounded-[4px] p-2">
                                            <ImageOptimizer
                                                className="w-full h-full object-contain flex justify-center items-center"
                                                formats={item.Files[0].formats}
                                                alt={item.name}
                                                width={256}
                                                height={256}
                                            />
                                        </div>
                                        <div className="flex flex-col gap-4 mb-auto mt-auto">
                                            <span className="bg-beige bg-opacity-50 rounded-[4px] uppercase py-[4px] px-[14px]">
                                                RÉF : {item.name}
                                            </span>
                                            <div className="flex flex-row gap-2">
                                                <div
                                                    className={`border-beige overflow-hidden transition-[border-width] ease bg-cover w-[50px] h-[50px] m-[5px] rounded-[50px] border-solid border-2`}
                                                >
                                                    <ImageOptimizer
                                                        className="w-full h-full object-cover flex justify-center items-center"
                                                        formats={item.selectedColor?.File?.formats}
                                                        width={256}
                                                        height={256}
                                                        alt={"Pied de lit Margot " + item.selectedColor.name}
                                                        title={item.selectedColor.name}
                                                    />
                                                </div>
                                                {item.selectedRingColor && (
                                                    <div
                                                        className={`border-beige overflow-hidden transition-[border-width] ease bg-cover w-[50px] h-[50px] m-[5px] rounded-[50px] border-solid border-2`}
                                                    >
                                                        <ImageOptimizer
                                                            className="w-full object-cover flex justify-center items-center"
                                                            formats={item.selectedRingColor?.File?.formats}
                                                            width={256}
                                                            height={256}
                                                            alt={"Pied de lit Margot " + item.selectedRingColor.name}
                                                            title={item.selectedRingColor.name}
                                                            style={{ width: "100%", height: "100%" }}
                                                            styleImage={{ width: "100%", height: "100%", ObjectFit: "full" }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                        )
                    ) : (
                        <></>
                    )}
                </div>
            </div>

            <Button
                variant="contained"
                sx={{
                    color: theme.palette.text.white,
                    fontSize: "20px",
                    padding: "8px 32px",
                    borderRadius: "50px",
                }}
                onClick={handleSubmit}
                className="!mt-10 self-center "
            >
                Valider ma demande
            </Button>
        </div>
    );
}
