import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useUserStore from "../contexts/UserStore";
import axiosInstance from "../utils/axiosConfig";

export const ProtectedRoutes = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const setUser = useUserStore((state) => state.setUser);
    const unsetUser = useUserStore((state) => state.unsetUser);

    useEffect(() => {
        axiosInstance("/seller/me")
            .then((response) => {
                setUser(response.data);
                setIsLoggedIn(true);
            })
            .catch((error) => {
                unsetUser();
                console.log(error);
                setIsLoggedIn(false);
                return navigate("/espace-pro/connexion", { replace: true });
            }); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    // If authenticated, render the child routes
    return <>{isLoggedIn ? <Outlet /> : null}</>;
};
