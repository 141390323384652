import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAllProductsForAideVente } from "../../api/Product";
import Ariane from "../../components/molecules/Ariane";
import ColorSelector from "../../components/molecules/ColorSelector";
import { theme } from "../../theme";
import ImageOptimizer from "../../utils/ImageOptimizer";
import MetaTagsMaster from "../../utils/MetaTagsMaster";
import ResumeAidesVente from "./ResumeAidesVente";

const products = [
    {
        id: 1,
        name: "Présentoir mobile",
        price: 45,
        comment: "Livré avec les 8 pieds de votre choix",
        link: "/images/presentoirMobile.png",
        slug: "presentoir-mobile",
    },
    {
        id: 2,
        name: "Présentoir mural (700 x 400 mm)",
        price: 45,
        comment: "Livré avec les 8 pieds de votre choix",
        link: "/images/presentoirMural.png",
        slug: "presentoir-mural",
    },
    { id: 3, name: "Nuancier boulier", price: 20, link: "/images/nuancier.png", slug: "nuancier" },
];

export default function AidesVenteProduct() {
    const slug = useParams().slug;
    const navigate = useNavigate();
    const [isValidate, setIsValdiate] = useState(false);
    const product = products.find((item) => item.slug === slug);
    product === undefined && navigate("/espace-pro/aides-vente");
    const [selectedProducts, setSelectedProducts] = useState([null, null, null, null, null, null, null, null]);
    const [arianeItem, setArianeItem] = useState();

    useEffect(() => {
        product.slug === "nuancier" && setIsValdiate(true);
        setArianeItem([
            ["/", "Accueil"],
            ["/espace-pro", " Espace professionnel"],
            ["/espace-pro/aides-vente", "Mes aides à la vente"],
            [`/espace-pro/aides-vente/${product.slug}`, product?.name],
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [productList, setProductList] = useState([]);

    const getAllProducts = async () => {
        try {
            const data = await getAllProductsForAideVente();
            setProductList(data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAllProducts();
    }, []);

    const [modalOpen, setModalOpen] = useState({
        open: false,
        item: null,
        type: "",
    });

    const selectProduct = (index, product) => {
        const newSelectedProducts = [...selectedProducts];
        const item = productList.find((item) => item.ref === product);
        newSelectedProducts[index] = {
            ...item,
            selectedColor: item.colors.length > 0 ? item.colors[0] : null,
            selectedRingColor: item.ring_colors.length > 0 ? item.ring_colors[0] : null,
        };
        setSelectedProducts(newSelectedProducts);
    };

    const changeItemColor = (ref, color, type) => {
        const newSelectedProducts = [...selectedProducts];
        const index = selectedProducts.findIndex((item) => item?.ref === ref);
        newSelectedProducts[index] = {
            ...newSelectedProducts[index],
            selectedColor: type === "color" ? color : newSelectedProducts[index].selectedColor,
            selectedRingColor: type === "ringColor" ? color : newSelectedProducts[index].selectedRingColor,
        };
        setSelectedProducts(newSelectedProducts);
        setModalOpen({
            open: false,
            item: null,
            type: "",
        });
    };

    return (
        <>
            <MetaTagsMaster robots={false} title="Aides à la vente" />
            <section className="bg-white pt-[32px]">
                <div className="container mx-auto">
                    <div className="pb-[64px] lg:flex items-center">
                        <Link to={"/espace-pro"} className="back-button" title="Espace pro">
                            Retour
                        </Link>
                        <Ariane arianeItem={arianeItem} className="lg:ml-[52px] lg:mt-0 mt-[16px]" />
                    </div>
                </div>
            </section>
            <section className="bg-white pb-[64px]">
                <div className="container mx-auto min-h-[420px]">
                    <div className="flex flex-col w-full h-full">
                        <h1 className="FuturaMediumCd text-6xl pb-[32px]">Mes aides à la vente</h1>
                        <p className="mb-4">Nous mettons à votre disposition tous les outils nécessaires pour mettre en avant les produits Margot.</p>
                        {isValidate ? (
                            <ResumeAidesVente
                                product={product}
                                choice={selectedProducts.every((element) => element === null) ? null : selectedProducts}
                            />
                        ) : (
                            <>
                                <div className="flex flex-wrap gap-12">
                                    {selectedProducts.map((item, index) => (
                                        <div className="form-control" key={index}>
                                            <label className="label">
                                                <span className="label-text">Pieds numéro {index + 1} :</span>
                                            </label>
                                            <select
                                                className="select border-solid border-2 border-black w-[15rem]"
                                                onChange={(e) => selectProduct(index, e.target.value)}
                                                defaultValue={""}
                                            >
                                                <option disabled value="">
                                                    Sélectionnez un pied
                                                </option>
                                                {productList.map((product, index) => (
                                                    <option key={index} value={product.ref}>
                                                        {product.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {item !== null && (
                                                <div className="mt-4 flex gap-4">
                                                    {item.colors.length > 0 && (
                                                        <div className="flex flex-col gap-2">
                                                            <p>Couleur</p>
                                                            <div
                                                                className={`${
                                                                    item.colors.length > 1 ? "cursor-pointer" : ""
                                                                } border-beige overflow-hidden transition-[border-width] ease bg-cover w-[50px] h-[50px] m-[5px] rounded-[50px] border-solid border-2`}
                                                                onClick={() => {
                                                                    item.colors.length > 1 &&
                                                                        setModalOpen({
                                                                            open: true,
                                                                            item: item,
                                                                            type: "color",
                                                                        });
                                                                }}
                                                            >
                                                                <ImageOptimizer
                                                                    className="w-full object-cover flex justify-center items-center"
                                                                    formats={item.selectedColor?.File?.formats}
                                                                    width={256}
                                                                    height={256}
                                                                    alt={"Pied de lit Margot " + item.selectedColor.name}
                                                                    title={item.selectedColor.name}
                                                                    style={{ width: "100%", height: "100%" }}
                                                                    styleImage={{ width: "100%", height: "100%", ObjectFit: "full" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    {item.ring_colors.length > 0 && (
                                                        <div className="flex flex-col gap-2">
                                                            <p>Bagues</p>
                                                            <div
                                                                className={`${
                                                                    item.ring_colors.length > 1 ? "cursor-pointer" : ""
                                                                } border-beige overflow-hidden transition-[border-width] ease bg-cover w-[50px] h-[50px] m-[5px] rounded-[50px] border-solid border-2`}
                                                                onClick={() => {
                                                                    item.ring_colors.length > 1 &&
                                                                        setModalOpen({
                                                                            open: true,
                                                                            item: item,
                                                                            type: "ringColor",
                                                                        });
                                                                }}
                                                            >
                                                                <ImageOptimizer
                                                                    className="w-full object-cover flex justify-center items-center"
                                                                    formats={item.selectedRingColor?.File?.formats}
                                                                    width={256}
                                                                    height={256}
                                                                    alt={"Pied de lit Margot " + item.selectedRingColor.name}
                                                                    title={item.selectedRingColor.name}
                                                                    style={{ width: "100%", height: "100%" }}
                                                                    styleImage={{ width: "100%", height: "100%", ObjectFit: "full" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <Button
                                    variant="contained"
                                    disabled={selectedProducts.some((item) => item === null)}
                                    sx={{
                                        color: theme.palette.text.white,
                                        fontSize: "20px",
                                        padding: "8px 32px",
                                        borderRadius: "50px",
                                    }}
                                    onClick={() => setIsValdiate(true)}
                                    className="!mt-10 self-center "
                                >
                                    Valider mes choix de pieds
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </section>
            {modalOpen.open && (
                <div className="modal opacity-100">
                    <div className="modal-content pointer-events-auto bg-white p-[2rem] rounded-[10px] max-w-[70vw]">
                        <ColorSelector
                            allDisplayed={true}
                            colors={modalOpen.type === "color" ? modalOpen.item.colors : modalOpen.item.ring_colors}
                            defaultColors={(modalOpen.type === "color" ? modalOpen.item.colors : modalOpen.item.ring_colors).findIndex(
                                (color) =>
                                    color.id === (modalOpen.type === "color" ? modalOpen.item.selectedColor : modalOpen.item.selectedRingColor).id
                            )}
                            onChange={(value) => changeItemColor(modalOpen.item.ref, value, modalOpen.type)}
                        />
                        <button
                            className="w-full ml-auto mr-auto mt-5"
                            onClick={() =>
                                setModalOpen({
                                    open: false,
                                    item: null,
                                    type: "",
                                })
                            }
                        >
                            Fermer
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}
