import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

// Définir le store
const userStore = (set, get) => ({
    user: null,

    // Obtenir l'utilisateur
    getUser: () => get().user,

    // Connexion / Actualisation de l'utilisateur
    setUser: (user) => {
        // On sauvegarde l'utilisateur dans le localStorage avec une date d'expiration de 48 heures
        const now = new Date();
        const expiry = now.getTime() + 48 * 60 * 60 * 1000;
        // const expiry = now.getTime() + 5 * 1000;

        //const userWithExpiry = { user, expiry };

        // localStorage.setItem("user", JSON.stringify(userWithExpiry));
        set({ user, expiry });
    },

    checkUserExpiry: () => {
        const userDataStr = localStorage.getItem("margot-user");

        if (userDataStr) {
            const userData = JSON.parse(userDataStr);
            const now = new Date();
            if (now.getTime() > userData.state.expiry) {
                // Si l'utilisateur a expiré, on le supprime
                // localStorage.removeItem("user");
                set({ user: null, expiry: null });
                localStorage.removeItem("margot-user");
            } else {
                // Si l'utilisateur n'a pas expiré, on le réinitialise dans Zustand
                set({ user: userData.state.user });
            }
        }
    },

    // Déconnexion
    unsetUser: () => set({ user: null, expiry: null }),
});

const useUserStore = create(
    persist(userStore, {
        name: "margot-user",
        storage: createJSONStorage(() => localStorage),
    })
);

export default useUserStore;
