import { createTheme } from "@mui/material/styles";

// Création et exportation du thème personnalisé
export const theme = createTheme({
    palette: {
        primary: {
            main: "#7bb92b", // couleur primaire personnalisée
        },
        text: {
            white: "#ffffff", // couleur de texte pour les boutons
            green: "#7bb92b",
        },
    },
    typography: {
        fontFamily: "futura_stdmedium_condensed", // police de caractères personnalisée
    },
});
