import React from "react";
import { Helmet } from "react-helmet-async";

export default function MetaTagsMaster({
    title,
    description,
    image,
    keywords,
    url,
    robots = true,
    jsonLd,
}) {
    const fullTitle = title
        ? `${title} | Margot Sa`
        : "Fabricant pieds de sommier | Margot Sa";

    // Utilisation de `new URL` pour une gestion plus propre de l'URL
    const currentUrl = (() => {
        try {
            const baseUrl = new URL(url || window.location.href);
            baseUrl.search = ""; // Supprime les paramètres de requête
            return baseUrl.toString();
        } catch (error) {
            console.error("Invalid URL:", error);
            return url || window.location.href.split("?")[0];
        }
    })();

    const defaultDescription =
        "Fabricant de pieds de sommiers depuis 1974. Chez nous l'essentiel est accessoire : pieds de lits, pieds de sommiers, encadrements, accessoires";

    const keywordsDefault = "Fabricant pieds de sommiers, pieds de lits";

    const imageDefault = "https://www.margotsa.fr/og-banner.jpg";

    return (
        <Helmet>
            {/* Configuration générale */}
            <meta name="author" content="RouleMarcel" />
            <meta name="color-scheme" content="light only" />

            <title>{fullTitle}</title>
            <meta name="title" content={fullTitle} />
            <meta
                name="robots"
                content={robots ? "index, follow" : "noindex, nofollow"}
            />
            <meta
                name="description"
                content={description || defaultDescription}
            />
            <link rel="canonical" href={currentUrl} />
            <meta name="keywords" content={keywords || keywordsDefault} />

            {/* Open Graph / Facebook */}
            <meta property="og:url" content={currentUrl} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="MargotSa" />
            <meta property="og:title" content={fullTitle} />
            <meta
                property="og:description"
                content={description || defaultDescription}
            />
            <meta property="og:image" content={image || imageDefault} />

            {/* Twitter */}
            <meta name="twitter:card" content="summary" />
            {/* Si vous souhaitez ajouter "twitter:site", décommentez la ligne suivante et remplacez la valeur. */}
            {/* <meta name="twitter:site" content="@Margot" /> */}
            <meta name="twitter:url" content={currentUrl} />
            <meta name="twitter:title" content={fullTitle} />
            <meta
                name="twitter:description"
                content={description || defaultDescription}
            />
            <meta name="twitter:image" content={image || imageDefault} />

            {/* JSON-LD */}
            {jsonLd && (
                <script type="application/ld+json">
                    {JSON.stringify(jsonLd, null, 2)}
                </script>
            )}
        </Helmet>
    );
}
