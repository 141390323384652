import { lazy } from "react";
import { ProtectedRoutes } from "./ProtectedRoutes";

const MainLayout = lazy(() => import("../layouts/MainLayout"));

const Home = lazy(() => import("../views/Home"));
const CataloguePiedLitCanape = lazy(() =>
    import("../views/CataloguePiedLitCanape")
);
const CatalogueAccessoire = lazy(() => import("../views/CatalogueAccessoires"));
const CatalogueComplements = lazy(() =>
    import("../views/CatalogueComplements")
);
const ComplementDetails = lazy(() => import("../views/ComplementDetails"));
const AccessoryDetails = lazy(() => import("../views/AccessoryDetails"));
const ProduitConfigurateur = lazy(() =>
    import("../views/ProduitConfigurateur")
);

const Faq = lazy(() => import("../views/Faq"));
const Contact = lazy(() => import("../views/Contact"));

const Actualites = lazy(() => import("../views/Actualites"));
const Actualite = lazy(() => import("../views/Actualites/Actualite"));

const SavoirFaire = lazy(() => import("../views/SavoirFaire"));
const Environnement = lazy(() => import("../views/SavoirFaire/Environnement"));
const SavoirFaire50 = lazy(() => import("../views/SavoirFaire/SavoirFaire-50"));
const ConceptArtisanal = lazy(() =>
    import("../views/SavoirFaire/ConceptArtisanal")
);
const DispoEtQualite = lazy(() => import("../views/SavoirFaire/DispoQualite"));

const Search = lazy(() => import("../views/Search"));
const Newsletter = lazy(() => import("../views/Newsletter"));
const Rappel = lazy(() => import("../views/Rappel"));
const Panier = lazy(() => import("../views/Panier"));
const Recapitulatif = lazy(() => import("../views/Recapitulatif"));
const NotFound = lazy(() => import("../views/NotFound"));
const Confirmation = lazy(() => import("../views/Confirmation"));
const LegalTerms = lazy(() => import("../views/LegalTerms"));
const PrivacyPolicy = lazy(() => import("../views/PrivacyPolicy"));
const CGV = lazy(() => import("../views/CGV"));
const Plansite = lazy(() => import("../views/Plansite"));

const FabricationFrançaise = lazy(() =>
    import("../views/SavoirFaire/Fabrication")
);

const MembersRoutes = {
    element: <MainLayout />,
    children: [
        {
            path: "/",
            element: <Home />,
        },

        {
            path: "pieds-de-lit-canape",
            element: <CataloguePiedLitCanape />,
        },
        {
            path: "pieds-de-lit-canape/:slug",
            element: <ProduitConfigurateur />,
        },
        {
            path: "accessoires",
            element: <CatalogueAccessoire />,
        },
        {
            path: "accessoires/:slug",
            element: <AccessoryDetails />,
        },
        {
            path: "complements-literies/:slug",
            element: <ComplementDetails />,
        },
        {
            path: "complements-literies",
            element: <CatalogueComplements />,
        },

        // Actualités
        {
            path: "/actualites",
            element: <Actualites />,
        },
        {
            path: "/actualites/:slug",
            element: <Actualite />,
        },

        {
            element: <ProtectedRoutes />,
            children: [
                // panier
                {
                    path: "recapitulatif",
                    element: <Recapitulatif />,
                },
                {
                    path: "panier",
                    element: <Panier />,
                },
                {
                    path: "/confirmation",
                    element: <Confirmation />,
                },
            ],
        },

        // contact
        {
            path: "contact",
            element: <Contact />,
        },

        // Rappel
        {
            path: "etre-rappele",
            element: <Rappel />,
        },

        // Newsletter
        {
            path: "newsletter",
            element: <Newsletter />,
        },

        // FAQ
        {
            path: "faq",
            element: <Faq />,
        },
        // Résultat de recherche
        {
            path: "recherches/:search",
            element: <Search />,
        },
        // Résultat de recherche
        {
            path: "la-societe-margot",
            element: <SavoirFaire />,
        },

        {
            path: "la-societe-margot/environnement",
            element: <Environnement />,
        },

        {
            path: "la-societe-margot/savoir-faire-français",
            element: <SavoirFaire50 />,
        },

        {
            path: "la-societe-margot/conception-artisanale",
            element: <ConceptArtisanal />,
        },

        {
            path: "la-societe-margot/disponibilite-et-qualite",
            element: <DispoEtQualite />,
        },

        {
            path: "la-societe-margot/fabrication-française",
            element: <FabricationFrançaise />,
        },
        {
            path: "*",
            element: <NotFound />,
        },

        {
            path: "/mentions-legales",
            element: <LegalTerms />,
        },
        {
            path: "/politique-de-confidentialite",
            element: <PrivacyPolicy />,
        },
        {
            path: "/cgv",
            element: <CGV />,
        },
        {
            path: "/plan-site",
            element: <Plansite />,
        },
    ],
};

export default MembersRoutes;
