import axiosInstance from "../utils/axiosConfig";

export const getAllProducts = async (page = 0, pageSize = 10, search = "", filter = []) => {
    // const containFamily = filter.some((f) => f.label === "family_id")
    // const containVariant = filter.some((f) => f.variant)
    const containOneFamily = filter.filter((f) => f.label === "family_id").length === 1;

    let url = `/product?pagination[page]=${page}&pagination[pageSize]=${pageSize}`;

    // Ajout du filtre de recherche si présent
    if (search) {
        url += `&filters[$or][0][name][$containsi]=${search}&filters[$or][1][ref][$containsi]=${search}`;
    }

    // Ajout des filtres de type AND si présents
    // Même logique pour les deux cas mais on garde le code pour la future réutilisation si on vient à filtrer sur plusieurs matières
    if (filter.length > 1 && containOneFamily) {
        filter.forEach((f, index) => {
            url += `&filters[$and][${index}]${f.variant ? "[variants]" : ""}${f.colors ? "[colors]" : ""}[${f.colors ? "name" : f.label}][$eq]=${
                f.value
            }`;
        });
    } else if (filter.length > 1) {
        filter.forEach((f, index) => {
            url += `&filters[$and][${index}]${f.variant ? "[variants]" : ""}${f.colors ? "[colors]" : ""}[${f.colors ? "name" : f.label}][$eq]=${
                f.value
            }`;
        });
    }

    if (filter.length === 1) {
        filter.forEach((f) => {
            url += `&filters${f.variant ? "[variants]" : ""}${f.colors ? "[colors]" : ""}[${f.colors ? "name" : f.label}][$eq]=${f.value}`;
        });
    }

    try {
        const response = await axiosInstance.get(url);
        return response.data;
    } catch (error) {
        console.error("Erreur lors de la récupération des produits:", error);
        throw error;
    }
};

export const getAllProductsForAideVente = async () => {
    try {
        const response = await axiosInstance.get(`/product/forAideVente`);
        return response.data;
    } catch (error) {
        console.error("Error fetching product:", error);
        throw error;
    }
};

export const getProduct = async (slug) => {
    try {
        const response = await axiosInstance.get(`/product/by-slug/${slug}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching product:", error);
        throw error;
    }
};

export const getRandomProducts = async (limit, id) => {
    try {
        const response = await axiosInstance.get(`/product/suggestions?limit=${limit}&excludeId=${id}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching random products:", error);
        throw error;
    }
};

export const getProductColor = async (id, type) => {
    try {
        const response = await axiosInstance.get(`/product/color/${id}?type=${type}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching random products:", error);
        throw error;
    }
};
