import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Loader from "./components/molecules/Loader";
import { ConsentProvider } from "./contexts/ConsentContext";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./styles/_index.scss";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <BrowserRouter basename="/">
        <ConsentProvider>
            <Suspense
                fallback={
                    <div className="fixed w-full h-full flex justify-center items-center">
                        <Loader />
                    </div>
                }
            >
                <App />
            </Suspense>
        </ConsentProvider>
    </BrowserRouter>
);

serviceWorkerRegistration.unregister();
